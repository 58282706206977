import { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ArtifactSubtopicPage from 'pages/Artifacts/ArtifactSubtopicPage'
import ArtifactTopicPage from 'pages/Artifacts/ArtifactTopicPage'
import LoggedOutArtifactPage from 'pages/Artifacts/LoggedOutArtifactPage'
import LoggedOutArtifactsIndexPage from 'pages/Artifacts/LoggedOutArtifactsIndexPage'
import BlogIndexPage from 'pages/Blog/BlogIndexPage'
import BlogPostPage from 'pages/Blog/BlogPostPage'
import BookADemoPage from 'pages/BookADemoPage/BookADemoPage'
import CareersPage from 'pages/CareersPage'
import CaseStudiesIndexPage from 'pages/CaseStudies/CaseStudiesIndexPage'
import CaseStudyDetailsPage from 'pages/CaseStudies/CaseStudyDetailsPage'
import ContactUsPage from 'pages/ContactUsPage/ContactUs'
import CopyRightDisputePage from 'pages/CopyrightDispute/CopyrightDispute'
import LoggedOutCourseDetailsPageBridge from 'pages/CourseDetailsPage/LoggedOutCourseDetailsPageBridge'
import CourseSeoLandingPage from 'pages/CourseSeoLandingPage/CourseSeoLandingPage'
import CourseSnapshotLandingPage from 'pages/CourseSnapshotLandingPage/CourseSnapshotLandingPage'
import LoggedOutCoursesIndexPageBridge from 'pages/CoursesIndexPage/LoggedOutCoursesIndexPageBridge'
import CoursesMarketplaceWaitlistPage from 'pages/CoursesMarketplaceWaitlist/CoursesMarketplaceWaitlist'
import CreatorLandingPage from 'pages/CreatorLandingPage'
import EmailVerifyPage from 'pages/EmailVerifyPage'
import EventCountdownPage from 'pages/EventCountdownPage/EventCountdownPage'
import LoggedOutEventPage from 'pages/EventPage/LoggedOutEventPage'
import LoggedOutEventPastRecordings from 'pages/EventRecordings/LoggedOutEventPastRecordings'
import LoggedOutEventsPage from 'pages/EventsPage/LoggedOutEventsPage'
import LoggedOutEventUpcoming from 'pages/EventsUpcoming/LoggedOutEventUpcoming'
import ExpertsIndexPage from 'pages/Experts/ExpertsIndexPage'
import ExploreSubtopicPage from 'pages/Explore/ExploreSubtopicPage/ExploreSubtopicPage'
import ExploreTopicPage from 'pages/Explore/ExploreTopicPage/ExploreTopicPage'
import LoggedOutExploreWrapper from 'pages/Explore/LoggedOutExploreWrapper'
import ExtensionMarketingPage from 'pages/ExtensionMarketingPage'
import LoggedOutGuidesDetailPage from 'pages/GuidesPage/LoggedOutGuidesDetail'
import LoggedOutGuidesIndexPage from 'pages/GuidesPage/LoggedOutGuidesIndex'
import LoggedOutHomepage from 'pages/HomePage/LoggedOutHomepage/LoggedOutHomepage'
import LoggedOutLessonViewer from 'pages/LessonViewer/LoggedOutLessonViewer'
import LoginPage from 'pages/LoginPage'
import MaintenancePage from 'pages/MaintenancePage'
import MarketingPricingPage from 'pages/MarketingPricingPage'
import OpenPositionsPage from 'pages/OpenPositionsPage'
import PodcastIndexPage from 'pages/Podcast/PodcastIndexPage'
import PodcastPage from 'pages/Podcast/PodcastPage'
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage'
import LoggedOutProfilePage from 'pages/Profiles/LoggedOutProfilePage'
import LoggedOutProgramPreviewPage from 'pages/ProgramPreviewPage/LoggedOutProgramPreviewPage'
import MarketingReimbursementPage from 'pages/ReimbursementPage/MarketingReimbursementPage'
import LoggedOutSearchResultsPage from 'pages/SearchResultsPage/LoggedOutSearchResultsPage'
import SnapshotLandingPage from 'pages/SnapshotLandingPage/SnapshotLandingPageContainer'
import TeamsGetInTouchPage from 'pages/TeamsGetInTouchPage'
import TeamsMarketingPage from 'pages/TeamsMarketingPage'
import TermsOfServicePage from 'pages/TermsOfServicePage'

import MakePayment from 'domains/Patronage/MakePayment'

import Layout from 'components/Layout'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { LESSON_VIEWER_URL } from 'utils/url'

import { ExternalRedirect } from './utils'

/* If a route is added, you need to add the path to the array in _analytics.html.erb where the
page tracking is occurring. This will prevent double firing of the analytics call. */

interface PublicRoutesProps {
  isLoggedIn: boolean
}

const PublicRoutes = ({ isLoggedIn }: PublicRoutesProps) => {
  const { showBrowseByTopics } = useFeatureFlags()

  return (
    <Switch>
      <PublicRoute exact path="/" component={LoggedOutHomepage} isLoggedIn={isLoggedIn} />

      <PublicRoute
        exact
        path="/events"
        component={LoggedOutEventsPage}
        isLoggedIn={isLoggedIn}
      />

      <PublicRoute
        exact
        path="/events/recordings"
        component={LoggedOutEventPastRecordings}
        isLoggedIn={isLoggedIn}
      />

      <PublicRoute
        exact
        path="/events/upcoming"
        component={LoggedOutEventUpcoming}
        isLoggedIn={isLoggedIn}
      />

      <PublicRoute
        exact
        path={[
          '/events/:id-:slug',
          '/events/:id-:slug/attendees',
          '/events/:id-:slug/summary',
          // has to be after :id-:slug otherwise :id will always match and include the slug
          '/events/:id'
        ]}
        component={LoggedOutEventPage}
        isLoggedIn={isLoggedIn}
      />

      <PublicRoute
        exact
        path="/events/links/:uuid"
        component={EventCountdownPage}
        isLoggedIn={isLoggedIn}
      />

      <PublicRoute
        exact
        path="/artifacts"
        component={LoggedOutArtifactsIndexPage}
        isLoggedIn={isLoggedIn}
      />
      <PublicRoute
        exact
        path="/artifacts/:slug"
        component={LoggedOutArtifactPage}
        isLoggedIn={isLoggedIn}
      />
      <PublicRoute
        exact
        path="/artifacts/c/:topic"
        component={ArtifactTopicPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/artifacts/c/:topic/:subtopic"
        component={ArtifactSubtopicPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/course-categories/:query"
        component={CourseSeoLandingPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/profiles/:slug"
        component={LoggedOutProfilePage}
        isLoggedIn={isLoggedIn}
      />
      <PublicRoute
        exact
        path="/pricing"
        component={MarketingPricingPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/courses"
        component={LoggedOutCoursesIndexPageBridge}
        isLoggedIn={isLoggedIn}
      />
      <PublicRoute
        exact
        path="/courses/:courseSlug/details"
        component={LoggedOutCourseDetailsPageBridge}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <Redirect exact from="/courses/:courseSlug" to="/courses/:courseSlug/details" />
      <PublicRoute
        exact
        path="/guides"
        component={LoggedOutGuidesIndexPage}
        isLoggedIn={isLoggedIn}
      />
      <PublicRoute
        exact
        path={['/guides/:slug']}
        component={LoggedOutGuidesDetailPage}
        isLoggedIn={isLoggedIn}
      />
      <PublicRoute
        exact
        path="/contact"
        component={ContactUsPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute exact path="/creators" component={CreatorLandingPage} />
      <PublicRoute
        exact
        path="/experts"
        component={ExpertsIndexPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <Redirect exact from="/experts/:slug" to="/profiles/:slug" />
      <PublicRoute
        exact
        path={['/blog', '/blog/category/:category?']}
        component={BlogIndexPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/blog/:slug(.+)"
        component={BlogPostPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/podcast/unsolicited-feedback"
        component={PodcastIndexPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/podcast/unsolicited-feedback/:slug"
        component={PodcastPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/case-studies"
        component={CaseStudiesIndexPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/case-studies/:slug"
        component={CaseStudyDetailsPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/faq"
        allowAuthenticatedView
        component={() => <ExternalRedirect url="https://reforge.helpscoutdocs.com/" />}
      />
      <PublicRoute
        exact
        path="/reimbursement"
        component={MarketingReimbursementPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/terms-of-service"
        component={TermsOfServicePage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/privacy-policy"
        component={PrivacyPolicyPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path={[
          '/courses-marketplace-waitlist',
          '/courses-marketplace-waitlist/course-full'
        ]}
        component={CoursesMarketplaceWaitlistPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/careers"
        component={CareersPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/open-positions"
        component={OpenPositionsPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/copyright-dispute"
        component={CopyRightDisputePage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/teams"
        component={TeamsMarketingPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/teams/get-in-touch"
        component={TeamsGetInTouchPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/book-a-demo"
        component={BookADemoPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path="/login"
        component={LoginPage}
        redirectIfLoggedIn={true}
        isLoggedIn={isLoggedIn}
      />
      <PublicRoute
        exact
        path="/admin-login"
        component={LoginPage}
        redirectIfLoggedIn={true}
        isLoggedIn={isLoggedIn}
      />
      <PublicRoute
        exact
        path="/maintenance"
        component={MaintenancePage}
        redirectIfLoggedIn={true}
        isLoggedIn={isLoggedIn}
      />
      <PublicRoute
        exact
        path="/emails/verify/:nonce"
        component={EmailVerifyPage}
        withLayout
      />
      <PublicRoute
        exact={false}
        path="/make_payment"
        component={MakePayment}
        withLayout={false}
      />
      <PublicRoute
        exact={false}
        path="/snapshots"
        component={SnapshotLandingPage}
        withLayout={false}
      />
      <PublicRoute
        exact={false}
        component={CourseSnapshotLandingPage}
        path="/course-snapshots/:token/:snapshotType"
        withLayout={false}
      />
      <PublicRoute
        exact={false}
        path="/enrollment_snapshots"
        component={SnapshotLandingPage}
        withLayout={false}
      />
      <PublicRoute
        exact
        path="/extension"
        component={ExtensionMarketingPage}
        isLoggedIn={isLoggedIn}
        allowAuthenticatedView
      />
      <PublicRoute
        exact
        path={LESSON_VIEWER_URL}
        component={LoggedOutLessonViewer}
        isLoggedIn={isLoggedIn}
      />
      <PublicRoute
        exact
        path={['/programs/:slug/preview', '/programs/:slug/preview/material']}
        component={LoggedOutProgramPreviewPage}
        isLoggedIn={isLoggedIn}
      />
      <PublicRoute
        exact
        path="/search"
        component={LoggedOutSearchResultsPage}
        isLoggedIn={isLoggedIn}
      />
      <PublicRoute
        exact
        path="/explore"
        component={() => <Redirect to="/" />}
        isLoggedIn={isLoggedIn}
      />
      {showBrowseByTopics && (
        <PublicRoute
          exact
          path="/explore/:topic"
          component={() => <LoggedOutExploreWrapper PageContent={ExploreTopicPage} />}
          isLoggedIn={isLoggedIn}
        />
      )}
      {showBrowseByTopics && (
        <PublicRoute
          exact
          path="/explore/:topic/:subtopic"
          component={() => <LoggedOutExploreWrapper PageContent={ExploreSubtopicPage} />}
          isLoggedIn={isLoggedIn}
        />
      )}
    </Switch>
  )
}

const PublicRoute = ({
  component: Component,
  exact,
  path,
  redirectIfLoggedIn = false,
  redirectTo,
  withLayout = false,
  isLoggedIn,
  allowAuthenticatedView
}: {
  component: FC<any>
  exact: boolean
  path: string | string[]
  redirectIfLoggedIn?: boolean
  redirectTo?: string
  withLayout?: boolean
  isLoggedIn?: boolean
  allowAuthenticatedView?: boolean
}) => {
  const ComponentOrRedirect = (props: any) => {
    if (redirectIfLoggedIn && isLoggedIn) {
      if (path === '/login') {
        // example of such URL http://reforge.com/login?fwd=http%3A%2F%reforge.com%3A3000%2Fupgrade, present on /membership CTA when logged in
        const fwd = new URLSearchParams(props.location.search).get('fwd')
        if (fwd?.includes('http')) {
          window.location.replace(fwd)
          return null
        } else {
          return <Redirect to={fwd || redirectTo || '/'} />
        }
      }

      return <Redirect to={redirectTo || '/'} />
    }

    if (isLoggedIn && !allowAuthenticatedView) {
      return null
    }

    return <Component {...props} />
  }

  if (withLayout) {
    return (
      <Layout showHeader={false}>
        <Route exact={exact} path={path} render={ComponentOrRedirect} />
      </Layout>
    )
  }

  return <Route exact={exact} path={path} render={ComponentOrRedirect} />
}

export default PublicRoutes
