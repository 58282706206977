import { PropsWithChildren } from 'react'

import FullWidthCohortCard from 'components/cards/FullWidthCohortCard'
import FullWidthCourseCard from 'components/cards/FullWidthCourseCard'
import RfHeader3 from 'components/typography/RfHeader/RfHeader3'

import { LiveCourseUserCourseFragment, UserCohortPartFragment } from 'gql'

type ActiveCourseCardWrapperProps = {
  key: string
} & PropsWithChildren<{}>

const ActiveCourseCardWrapper = ({ children }: ActiveCourseCardWrapperProps) => (
  <div className="mb-5 rounded-xl bg-gradient-to-r from-rb-background-paleLavender/40 to-[#E1FFE4]/40 p-6">
    <span className="rounded-full bg-[#1D7D7A] px-2 text-sm text-white">Active</span>
    <div className="mt-2">{children}</div>
  </div>
)

interface ActiveCoursesSectionProps {
  courses: LiveCourseUserCourseFragment[]
  cohorts: UserCohortPartFragment[]
}

const ActiveCoursesSection = ({ courses, cohorts }: ActiveCoursesSectionProps) => (
  <span data-test="course-live-courses-section">
    <RfHeader3 className="font-polysans !font-light">My Live Courses</RfHeader3>
    {courses.map(({ courseSession, id }) => (
      <ActiveCourseCardWrapper key={id}>
        <FullWidthCourseCard
          pageLocation="courses_my_live_courses"
          courseSession={courseSession}
        />
      </ActiveCourseCardWrapper>
    ))}
    {cohorts.map(({ cohort, id }) => (
      <ActiveCourseCardWrapper key={id}>
        <FullWidthCohortCard
          eventCount={cohort.eventCount}
          startsAt={cohort.startsAt}
          endsAt={cohort.endsAt}
          programName={cohort.cmsProgram.name}
          cohortViewerHref={`/cohorts/${cohort.slug}`}
          onModifyClick={() => {}}
          progressPercent={cohort.cmsProgram.progressPercent}
          openForEnrollment={false}
        />
      </ActiveCourseCardWrapper>
    ))}
  </span>
)

interface PastCoursesSectionProps {
  hasActiveEvents: boolean
  courses: LiveCourseUserCourseFragment[]
  cohorts: UserCohortPartFragment[]
}

const PastCoursesSection = ({
  courses,
  cohorts,
  hasActiveEvents
}: PastCoursesSectionProps) => (
  <span data-test="course-past-courses-section">
    {hasActiveEvents ? (
      <h4>Past Courses</h4>
    ) : (
      <RfHeader3 className="font-polysans !font-light">Past Courses</RfHeader3>
    )}
    {courses.map(({ courseSession, id }) => (
      <div key={id} className="mb-5">
        <FullWidthCourseCard
          pageLocation="courses_my_live_courses"
          courseSession={courseSession}
        />
      </div>
    ))}
    {cohorts.map(({ id, cohort }) => (
      <div key={id} className="mb-5">
        <FullWidthCohortCard
          eventCount={cohort.eventCount}
          startsAt={cohort.startsAt}
          endsAt={cohort.endsAt}
          programName={cohort.cmsProgram.name}
          cohortViewerHref={`/cohorts/${cohort.slug}`}
          onModifyClick={() => {}}
          progressPercent={cohort.cmsProgram.progressPercent}
          openForEnrollment={false}
        />
      </div>
    ))}
  </span>
)

interface LiveCoursesProps {
  courses: LiveCourseUserCourseFragment[]
  cohorts: UserCohortPartFragment[]
}

const LiveCourses = ({ courses, cohorts }: LiveCoursesProps) => {
  const pastCourses = courses?.filter((course) => course.courseSession?.isPast) || []
  const upcomingOrCurrentCourses =
    courses?.filter((course) => !course.courseSession?.isPast) || []
  const pastCohorts = cohorts?.filter(({ cohort }) => cohort.coreWeeksHaveEnded) || []
  const activeCohorts = cohorts?.filter(({ cohort }) => !cohort.coreWeeksHaveEnded) || []

  const hasPastEvents = pastCourses.length > 0 || pastCohorts.length > 0
  const hasActiveEvents = upcomingOrCurrentCourses.length > 0 || activeCohorts.length > 0

  return (
    <div className="hidden min-w-[250px] max-w-[300px] flex-col space-y-2 md:block">
      {hasActiveEvents && (
        <ActiveCoursesSection
          courses={upcomingOrCurrentCourses}
          cohorts={activeCohorts}
        />
      )}
      {hasPastEvents && (
        <PastCoursesSection
          courses={pastCourses}
          cohorts={pastCohorts}
          hasActiveEvents={hasActiveEvents}
        />
      )}
    </div>
  )
}

export default LiveCourses
