import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  WithStore
} from 'pure-react-carousel'
import { Fragment, ReactNode, RefObject, forwardRef, useEffect, useState } from 'react'

import Loading from 'components/Loading'
import ArtifactCard from 'components/cards/Content/ArtifactCard'
import CourseCard from 'components/cards/Content/CourseCard'
import GuideCard from 'components/cards/Content/GuideCard'

import {
  MAX_WIDTH_TAILWIND_XS,
  MIN_WIDTH_TAILWIND_LG,
  MIN_WIDTH_TAILWIND_XL
} from 'constants/breakpoints'

import {
  CclGuide,
  SavedArtifactsAndGuidesForUserQuery,
  SavedCoursesForUserQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import { ReactComponent as ChevronLeftIcon } from 'images/icon--thin-chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'images/icon--thin-chevron-right.svg'

type Tracking = {
  location: string
  locationId: string | undefined
  locationType?: string
  sectionId?: string | undefined
  contentType: string
  section: string
  sectionIndex: number
  relatedIdentifiers: {
    locationName: string | undefined
  }
}

interface ExploreSwimlaneProps {
  idx: number
  loading?: boolean
  title?: ReactNode
  items: any[]
  topicId: string | undefined
  subtopicId?: string | undefined
  subtopicTitle?: string | undefined
  openAddToBookmarkFolderModal?: (bookmark: any) => void
  className?: string
  savedCourseData?: SavedCoursesForUserQuery | undefined
  savedData?: SavedArtifactsAndGuidesForUserQuery | undefined
  tracking: Tracking
}

interface CarouselContainerCarouselState {
  currentSlide: number
  totalSlides: number
  visibleSlides: number
}

function CarouselItem({
  item,
  idx,
  rowIdx,
  topicId,
  subtopicId,
  subtopicTitle,
  savedData,
  savedCourseData,
  openAddToBookmarkFolderModal,
  tracking
}: {
  item: any
  idx: number
  rowIdx: number
  topicId: string | undefined
  subtopicId: string | undefined
  subtopicTitle: string | undefined
  savedData: SavedArtifactsAndGuidesForUserQuery | undefined
  savedCourseData: SavedCoursesForUserQuery | undefined
  openAddToBookmarkFolderModal?: (bookmark: any) => void
  tracking: Tracking
}) {
  const { currentUser, isLoggedIn } = useCurrentUser()
  if (item.__typename === 'CclArtifact') {
    return (
      <ArtifactCard
        artifact={item}
        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
        // Needed because the slider manages the container overflow and will clip the dropdown menu
        bookmarkDropdownPosition="top"
        baseCardClassName="min-w-0"
        bookmark={savedData?.savedArtifactsAndGuidesForUser?.find(
          (bookmark) => bookmark.sanityId === item.sanityId
        )}
        pageLocation={tracking.location}
        impressionTrackingProperties={{
          accessPolicyKind: currentUser?.accessPolicyKind,
          location: tracking.location,
          locationId: tracking.locationId,
          section: tracking.section,
          sectionId: tracking.sectionId,
          sectionTitle: tracking.section,
          sectionIndex: tracking.sectionIndex,
          sectionImpressionIndex: idx,
          type: 'artifact',
          cclEntityId: item.id
        }}
        sectionIndex={rowIdx}
        sectionImpressionIndex={idx}
        additionalRelatedIdentifiers={{
          location_ccl_filter_tag_id: subtopicId,
          location_name: subtopicTitle?.toLowerCase()
        }}
        hideBookmarkButton={!isLoggedIn}
      />
    )
  }

  if (item.__typename === 'CclCourse') {
    return (
      <CourseCard
        course={item}
        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
        bookmark={savedCourseData?.savedCoursesForUser?.find(
          (bookmark) => bookmark.sanityId === item.sourceId
        )}
        bookmarkDropdownPosition="top"
        pageLocation={location.pathname}
        locationId={topicId}
        locationType={tracking.locationType}
        additionalRelatedIdentifiers={{
          destination_ccl_course_id: item.id,
          destination_name: item.title
        }}
        sectionIndex={rowIdx}
        sectionImpressionIndex={idx}
        impressionTrackingProperties={{
          accessPolicyKind: currentUser?.accessPolicyKind,
          locationId: tracking.locationId,
          location: tracking.location,
          section: tracking.section,
          sectionId: tracking.sectionId,
          sectionTitle: tracking.section,
          sectionIndex: tracking.sectionIndex,
          sectionImpressionIndex: idx,
          type: 'course',
          cclEntityId: item.id
        }}
        hideBookmarkButton={!isLoggedIn}
      />
    )
  }

  if (item.__typename === 'CclGuide') {
    return (
      <GuideCard
        guide={{ ...item, sourceId: item.sanityId } as CclGuide}
        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
        bookmark={savedData?.savedArtifactsAndGuidesForUser?.find(
          (bookmark) => bookmark.sanityId === item.sanityId
        )}
        bookmarkDropdownPosition="top"
        pageLocation={tracking.location}
        impressionTrackingProperties={{
          accessPolicyKind: currentUser?.accessPolicyKind,
          locationId: tracking.locationId,
          location: tracking.location,
          section: tracking.section,
          sectionId: tracking.sectionId,
          sectionTitle: tracking.section,
          sectionIndex: tracking.sectionIndex,
          sectionImpressionIndex: idx,
          type: 'guide',
          cclEntityId: item.id
        }}
        sectionIndex={rowIdx}
        sectionImpressionIndex={idx}
        additionalRelatedIdentifiers={{
          location_ccl_filter_tag_id: subtopicId,
          location_name: subtopicTitle?.toLowerCase()
        }}
        hideBookmarkButton={!isLoggedIn}
      />
    )
  }

  return null
}

const useSwimlanesSlidesCount = () => {
  const thirdSlide = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_LG})`)
  const fourthSlide = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_XL})`)

  return [fourthSlide, thirdSlide, true, true].filter((slide) => slide).length
}

export const ExploreSwimlane = ({
  idx,
  loading,
  title,
  items,
  topicId,
  subtopicId,
  subtopicTitle,
  savedData,
  savedCourseData,
  openAddToBookmarkFolderModal,
  tracking
}: ExploreSwimlaneProps) => {
  const visibleSlides = useSwimlanesSlidesCount()

  return (
    <CarouselProvider
      naturalSlideWidth={296}
      naturalSlideHeight={400}
      totalSlides={items.length + 1} // +1 for the empty element
      visibleSlides={visibleSlides}
      isIntrinsicHeight={true}
      dragEnabled={false}
      className="w-[calc(100%+24px)] lg:w-[calc(100%+4px)]"
    >
      <CarouselWithStore
        idx={idx}
        loading={loading}
        title={title}
        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
        items={items}
        topicId={topicId}
        subtopicId={subtopicId}
        subtopicTitle={subtopicTitle}
        visibleSlides={visibleSlides}
        savedData={savedData}
        savedCourseData={savedCourseData}
        tracking={tracking}
      />
    </CarouselProvider>
  )
}

interface CarouselProps {
  idx: number
  loading?: boolean
  title: ReactNode
  items: any[]
  topicId: string | undefined
  subtopicId: string | undefined
  subtopicTitle: string | undefined
  openAddToBookmarkFolderModal?: (bookmark: any) => void
  visibleSlides: number
  savedData?: SavedArtifactsAndGuidesForUserQuery | undefined
  savedCourseData: SavedCoursesForUserQuery | undefined
  tracking: Tracking
}

const BUTTON_STYLINGS = 'text-rb-black flex items-center justify-center'

const Carousel = forwardRef(
  (
    {
      idx,
      loading,
      items,
      topicId,
      subtopicId,
      subtopicTitle,
      title,
      openAddToBookmarkFolderModal,
      visibleSlides,
      currentSlide,
      savedData,
      savedCourseData,
      tracking
    }: CarouselProps & CarouselContainerCarouselState,
    // Required otherwise there will be an error in console.
    ref: RefObject<HTMLDivElement> // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => {
    const { isLoggedIn } = useCurrentUser()

    const [slideWidth, setSlideWidth] = useState(0)
    const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
    const gapWidth = 24
    const minCardWidth = 272
    const containerWidth = document.body.offsetWidth - 16 * 2 // remove the left/right content padding - only used on mobile view
    const totalSlidesWidth = slideWidth * visibleSlides
    const totalGapWidth = gapWidth * visibleSlides
    const sliderWidth = totalSlidesWidth + totalGapWidth
    const emptyElementWidth = isMobileView
      ? slideWidth - Math.abs(containerWidth - sliderWidth) + 2 * gapWidth
      : 0
    const isLastSlideVisible = items.length - visibleSlides === currentSlide
    const isFirstSlideVisible = currentSlide === 0

    useEffect(() => {
      document.body.classList.add('overscroll-x-none')

      return () => {
        document.body.classList.remove('overscroll-x-none')
      }
    }, [])

    useEffect(() => {
      const onResize = () => {
        if (isMobileView) {
          const containerWidth = document.body.offsetWidth - 16 * 2
          setSlideWidth(
            Math.max(containerWidth * 0.7 + gapWidth, minCardWidth + gapWidth)
          )
        }
      }
      window.addEventListener('resize', onResize)

      onResize()

      return () => {
        window.removeEventListener('resize', onResize)
      }
    }, [isMobileView])

    return (
      <div className="relative w-full overflow-x-hidden pb-4">
        {/* At some page widths, the hidden overflow doesn't completely cover the off screen item, due to the relative percentage widths of the cards. This div covers the bleeding content  */}
        <div className="absolute right-0 top-0 z-1 h-full w-2 bg-rb-white" />
        <div className="mb-6 flex items-center justify-between text-xl font-semibold">
          <div className="text-[20px] font-medium leading-[24px]">{title}</div>
          <div
            className={cn('hidden justify-end gap-2 pr-6 sm:flex', {
              'md:hidden': items.length <= visibleSlides
            })}
          >
            <ButtonBack
              className={cn(BUTTON_STYLINGS, {
                'cursor-default ': isFirstSlideVisible
              })}
              disabled={isFirstSlideVisible}
              onClick={() => {
                trackNavigationClicked({
                  type: 'carousel',
                  text: '<',
                  location: `${tracking.contentType}_swimlane`,
                  location_id: tracking.locationId,
                  location_type: tracking.locationType,
                  logged_in: isLoggedIn,
                  related_identifiers: {
                    location_name: tracking.relatedIdentifiers?.locationName
                  }
                })
              }}
            >
              <ChevronLeftIcon
                className={cn('h-4 w-4 text-rb-black', {
                  'text-rb-gray-100': isFirstSlideVisible
                })}
                fill="currentColor"
              />
            </ButtonBack>
            <ButtonNext
              className={cn(BUTTON_STYLINGS, {
                'cursor-default': isLastSlideVisible
              })}
              disabled={isLastSlideVisible}
              onClick={() => {
                trackNavigationClicked({
                  type: 'carousel',
                  text: '>',
                  location: `${tracking.contentType}_swimlane`,
                  location_id: tracking.locationId,
                  location_type: tracking.locationType,
                  logged_in: isLoggedIn,
                  related_identifiers: {
                    location_name: tracking.relatedIdentifiers?.locationName
                  }
                })
              }}
            >
              <ChevronRightIcon
                className={cn('h-4 w-4 text-rb-black', {
                  'text-rb-gray-100': isLastSlideVisible
                })}
                fill="currentColor"
              />
            </ButtonNext>
          </div>
        </div>
        {loading ? (
          <div className="my-16">
            <Loading />
          </div>
        ) : (
          <Slider
            style={{
              width: isMobileView ? `${totalSlidesWidth}px` : null
            }}
            classNameAnimation="transition-transform"
            trayProps={{
              draggable: true
            }}
          >
            {items.map((item: any, i: number) => {
              return (
                <Fragment key={i}>
                  {/* Hack - we render the below div with changing width to mimic behavior of homepage UiKit carousel: */}
                  {/* - sticking the last Slide to the right edge of the container when it becomes the active slide */}
                  {i === items.length - visibleSlides && (
                    <div
                      className="h-[1px] transition-width"
                      style={{
                        width:
                          isMobileView && currentSlide >= i + 1
                            ? `${emptyElementWidth}px`
                            : 0
                      }}
                    />
                  )}

                  <Slide
                    index={i}
                    key={i}
                    innerClassName="flex h-full"
                    style={
                      isMobileView
                        ? { paddingRight: gapWidth, width: slideWidth }
                        : { paddingRight: gapWidth }
                    }
                  >
                    <CarouselItem
                      item={item}
                      idx={i}
                      rowIdx={idx}
                      topicId={topicId}
                      subtopicId={subtopicId}
                      subtopicTitle={subtopicTitle}
                      savedData={savedData}
                      savedCourseData={savedCourseData}
                      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                      tracking={tracking}
                    />
                  </Slide>
                </Fragment>
              )
            })}
          </Slider>
        )}
      </div>
    )
  }
)

Carousel.displayName = 'Carousel'

const CarouselWithStore = WithStore<CarouselProps, CarouselContainerCarouselState>(
  Carousel,
  (state) => ({
    currentSlide: state.currentSlide,
    totalSlides: state.totalSlides,
    visibleSlides: state.visibleSlides
  })
)

export default ExploreSwimlane
