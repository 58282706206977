import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

type ButtonGoogleProps = {
  href: string
}

const ButtonGoogle = ({ href }: ButtonGoogleProps) => (
  <Button
    className="relative py-4 w-full text-sm font-medium normal-case"
    size="medium"
    variant="outline"
    href={href}
    data-method="post"
    title="Continue with Google"
    iconBefore={<SVGIcon name="google-minimal" />}
  >
    Continue with Google
  </Button>
)

export default ButtonGoogle
