import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export interface InterventionCardProps {
  titleComponent?: ReactNode
  subtitleComponent?: ReactNode
  className?: string
}

export const InterventionCard = ({
  titleComponent,
  subtitleComponent,
  className
}: InterventionCardProps) => {
  return (
    <div
      data-testid="intervention-card"
      className={twMerge(
        'mb-10 flex w-full flex-col space-y-1 rounded-lg bg-gradient-to-r from-rb-background-paleLavender/40 to-[#E1FFE4]/40 p-8',
        !subtitleComponent && 'pb-4',
        className
      )}
    >
      {titleComponent}
      {subtitleComponent}
    </div>
  )
}

export default InterventionCard
