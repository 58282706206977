import React from 'react'

import Button from 'components/Button'

import { type PlanOptionFields } from 'constants/plans'

import { PlanName } from 'gql'

import { titleize } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { Container } from './components/Container'
import { FeatureBreakdown } from './components/FeatureBreakdown'
import { PlanAndPricingInfo } from './components/PlanAndPricingInfo'
import { Title } from './components/Title'
import { isPlanDowngrade } from './helpers'

export type PlanOptionCardProps = Omit<
  PlanOptionFields,
  'name' | 'secondSubtitle' | 'features'
> & {
  cardPlanName: PlanName
  currentPlanName: PlanName
  currentNumSeats: number
  isSubscriptionOwner: boolean
  upcomingNumCohortPasses: number
  onPlanSelected: () => void
}

// NOTE: This is used on the Manage Plan page, implying this is shown to existing users
// we may want to rename this component to something like ExistingUsersPlanOptionCard
// to distinguish from others
export const PlanOptionCard = ({
  cardPlanName,
  currentPlanName,
  currentNumSeats,
  isSubscriptionOwner,
  title,
  subtitle,
  price,
  savingsTagContent,
  content,
  onPlanSelected,
  headerBarBgClass = 'bg-rb-green-50'
}: PlanOptionCardProps) => {
  const isCurrentPlan = cardPlanName === currentPlanName
  const isDowngrade = isPlanDowngrade(cardPlanName, currentPlanName, currentNumSeats)

  const getCtaText = () => {
    if (isCurrentPlan) {
      return `You are on ${titleize(cardPlanName)}`
    }
    if (isDowngrade) {
      return 'Get in Touch'
    }
    return `Select ${titleize(cardPlanName)}`
  }

  const handleCtaClick = () => {
    if (cardPlanName === PlanName.ENTERPRISE || currentPlanName === PlanName.ENTERPRISE) {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: 'manage_plan_page',
        text: getCtaText().toLowerCase(),
        destination: 'plan_update_request_form'
      })
      return window.open('/plan-update-request', '_blank')
    }

    if (isDowngrade) {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: 'manage_plan_page',
        text: getCtaText().toLowerCase(),
        destination: 'email_support'
      })
      return window.open('mailto:hello@reforge.com', '_blank')
    }

    trackCtaClicked({
      cta_type: 'button',
      cta_location: 'manage_plan_page',
      text: getCtaText().toLowerCase()
    })

    onPlanSelected()
  }

  return (
    <Container
      headerBarBgClass={headerBarBgClass}
      isPlanCardHighlighted={isCurrentPlan}
      highlightedHeaderText="Your Current Plan"
    >
      <div className="mb-12">
        <Title text={title} savingsTagContent={savingsTagContent} />
        <PlanAndPricingInfo price={price} />
        <p className="text-sm text-rb-gray-300">{subtitle}</p>
        {cardPlanName === PlanName.STARTER && (
          <div className="mb-6 text-sm text-rb-success-200">
            We suggest at least 2 Live Course Passes for Starter plans.
          </div>
        )}
        {cardPlanName === PlanName.SCALE && (
          <div className="mb-6 text-sm text-rb-success-200">
            We suggest at least 6 Live Course Passes for Scale plans.
          </div>
        )}
        {React.isValidElement(content) ? (
          content
        ) : (
          <FeatureBreakdown features={content} showExcludedFeatures={true} />
        )}
      </div>

      {isSubscriptionOwner && (
        <Button
          className="mt-auto w-full normal-case"
          size="small"
          variant="outline"
          disabled={isCurrentPlan}
          onClick={handleCtaClick}
          dataTest="planOptionCardCta"
        >
          {getCtaText()}
        </Button>
      )}
    </Container>
  )
}
