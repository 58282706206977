import { ReactComponent as SparkleIcon } from 'icon--sparkle.svg'
import React, { useMemo } from 'react'

import { useCohortPassOptIn } from 'domains/Subscription/CohortPassOptInModalContent/useCohortPassOptIn'
import { useCohortPassOptOut } from 'domains/Subscription/CohortPassOptOutModalContent/useCohortPassOptOut'
import { useRestartPlanNowModal } from 'domains/Subscription/RestartPlanNowModal/useRestartPlanNowModal'
import { useUpdatePlanNowModal } from 'domains/Subscription/UpdatePlanNowModal/useUpdatePlanNowModal'

import Button from 'components/Button'
import { useCheckoutModal } from 'components/CheckoutModal/useCheckoutModal'

import { PlanName } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { titleize } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { PlanOptionCardProps } from './PlanOptionCard'
import { Container } from './components/Container'
import { FeatureBreakdown } from './components/FeatureBreakdown'
import { PlanAndPricingInfo } from './components/PlanAndPricingInfo'
import { Title } from './components/Title'
import { isPlanDowngrade } from './helpers'

export interface LegacySubPlanOptionCardProps
  extends Omit<PlanOptionCardProps, 'onPlanSelected' | 'secondSubtitle' | 'features'> {
  upcomingPlanName?: PlanName
  isCanceled?: boolean | null
}

export const LegacySubPlanOptionCard = ({
  cardPlanName,
  currentPlanName,
  currentNumSeats,
  title,
  subtitle,
  price,
  savingsTagContent,
  content,
  headerBarBgClass = 'bg-rb-green-50',
  upcomingPlanName,
  upcomingNumCohortPasses,
  maxSeatCount,
  isCanceled
}: LegacySubPlanOptionCardProps) => {
  const isSelectedPlan = cardPlanName === upcomingPlanName
  const isDowngradeFromCurrentPlan = isPlanDowngrade(
    cardPlanName,
    currentPlanName,
    currentNumSeats
  )
  const cardPlanDetails = {
    name: cardPlanName,
    pricePerYear: price,
    maxSeatCount
  }

  const { showRestartFlow } = useFeatureFlags()

  const { openUpdatePlanNowModal } = useUpdatePlanNowModal()
  const { openCohortPassOptInModal } = useCohortPassOptIn()
  const { openCohortPassOptOutModal } = useCohortPassOptOut()
  const { openCheckoutModal } = useCheckoutModal()
  const { openRestartPlanNowModal } = useRestartPlanNowModal()

  const shouldLaunchRestartFlow = showRestartFlow && isCanceled

  const getCtaText = () => {
    if (isDowngradeFromCurrentPlan || cardPlanName === 'enterprise') {
      return 'Get in Touch'
    }

    if (isSelectedPlan) return 'Update Now'

    return `Select ${titleize(cardPlanName)}`
  }

  const isIndividualPlanOption = cardPlanName === PlanName.INDIVIDUAL
  const numCohortPasses = useMemo(() => {
    // If the user is on the individual plan, has selected to update now to it, and has 2 cohort passes on their upcoming invoice (meaning they have the individual + 2 discounted cohort passes bundle selected for upgrading at renewal)
    if (isIndividualPlanOption && upcomingNumCohortPasses === 2 && isSelectedPlan) {
      return upcomingNumCohortPasses // we show the 2 discounted cohort passes in the checkout modal
    }

    // if the user is not on the individual plan, but is selecting the individual plan - we set the numCohortPasses to 0 so the cohort passes section is hidden in the "Update at renewal" modal
    if (isIndividualPlanOption && !isSelectedPlan) {
      return 0
    }

    // in all other cases, return "undefined" so the Add-ons (cohort passes) section on the "Update at renewal" modal is displayed
    return undefined
  }, [isIndividualPlanOption, isSelectedPlan, upcomingNumCohortPasses])

  const handleCtaClick = () => {
    if (isDowngradeFromCurrentPlan) {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: 'manage_plan_page',
        text: getCtaText().toLowerCase(),
        destination: 'email_support'
      })
      return window.open('mailto:hello@reforge.com', '_blank')
    }

    if (cardPlanName === 'enterprise') {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: 'manage_plan_page',
        text: getCtaText().toLowerCase(),
        destination: 'plan_update_request_form'
      })
      return window.open('/plan-update-request', '_blank')
    }

    trackCtaClicked({
      cta_type: 'button',
      cta_location: 'manage_plan_page',
      text: `${getCtaText().toLowerCase()}${
        shouldLaunchRestartFlow ? ' (restart subscription)' : ''
      }`
    })

    const isIndividualPlanOption = cardPlanName === PlanName.INDIVIDUAL

    if (shouldLaunchRestartFlow) {
      openRestartPlanNowModal({ selectedPlan: cardPlanDetails, numCohortPasses })
      return
    }

    if (isSelectedPlan) {
      openCheckoutModal({
        planName: cardPlanDetails.name,
        isIndividual: isIndividualPlanOption,
        numCohortPasses
      })
      return
    }

    openUpdatePlanNowModal({
      selectedPlan: cardPlanDetails,
      isIndividual: isIndividualPlanOption,
      numCohortPasses
    })
  }

  const getCohortPassCta = () => {
    let text = 'Select Plan + Live Course Passes'
    let classes = 'w-auto ml-1'
    let clickHandler = () => {
      trackCtaClicked({
        cta_location: 'manage_plan_page',
        cta_type: 'button',
        text: `select plan + Live Course Passes${
          shouldLaunchRestartFlow ? ' (restart subscription)' : ''
        }`
      })

      if (shouldLaunchRestartFlow) {
        return openRestartPlanNowModal({
          selectedPlan: cardPlanDetails,
          numCohortPasses: 2
        })
      }

      const isIndividualPlanOption = cardPlanName === PlanName.INDIVIDUAL
      openUpdatePlanNowModal({
        selectedPlan: cardPlanDetails,
        numCohortPasses: 2,
        isIndividual: isIndividualPlanOption
      })
    }

    if (upcomingNumCohortPasses === 0 && upcomingPlanName === 'individual') {
      text = 'Add Live Course Passes'
      classes = 'ml-6'
      clickHandler = () => {
        trackCtaClicked({
          cta_location: 'manage_plan_page',
          cta_type: 'button',
          text: 'add Live Course Passes'
        })
        openCohortPassOptInModal()
      }
    }

    if (upcomingNumCohortPasses === 2 && upcomingPlanName === 'individual') {
      text = 'Remove Live Course Passes'
      classes = 'ml-6'
      clickHandler = () => {
        trackCtaClicked({
          cta_location: 'manage_plan_page',
          cta_type: 'button',
          text: 'remove Live Course Passes'
        })
        openCohortPassOptOutModal()
      }
    }

    return (
      <Button size="x-small" variant="outline" className={classes} onClick={clickHandler}>
        {text}
      </Button>
    )
  }

  const getSubtitle = () => {
    if (
      cardPlanName === 'individual' &&
      upcomingPlanName === 'individual' &&
      upcomingNumCohortPasses === 2
    ) {
      return (
        <>
          <p className="-mt-2 mb-1">
            <span className="text-base font-semibold text-rb-black">+ $995</span>
            <span className="text-sm text-rb-gray-300"> for 2 Live Course Passes</span>
          </p>
          <p className="mb-4 text-xs text-rb-gray-300">{subtitle}</p>
        </>
      )
    }
    return <p className="mb-8 text-sm text-rb-gray-300">{subtitle}</p>
  }

  return (
    <Container
      headerBarBgClass={headerBarBgClass}
      isPlanCardHighlighted={isSelectedPlan}
      highlightedHeaderText="Your Selected Plan"
    >
      <div className="flex h-full flex-col" data-test={`${cardPlanName}-plan-card`}>
        <Title text={title} savingsTagContent={savingsTagContent} />
        <PlanAndPricingInfo price={price} />
        {getSubtitle()}

        <div className="mb-6">
          <Button
            className="mt-auto w-full normal-case"
            size="small"
            variant="outline"
            onClick={handleCtaClick}
            dataTest="planOptionCardCta"
          >
            {getCtaText()}
          </Button>
        </div>

        <div className="flex h-full flex-col">
          {React.isValidElement(content) ? (
            content
          ) : (
            <FeatureBreakdown features={content} showExcludedFeatures={false} />
          )}

          {/* Show teal box at bottom for non-enterprise legacy users */}
          {cardPlanName !== 'enterprise' && (
            <div className="mt-auto rounded border border-rb-teal-200 bg-rb-teal-50 align-middle text-sm">
              {/* If individual, show info box with CTA on individual plan option  */}
              {cardPlanName === 'individual' &&
              (currentPlanName === 'individual' ||
                (currentPlanName === 'unlimited' && currentNumSeats === 1)) ? (
                <div className="p-2.5">
                  <span className="mb-2 block font-semibold">
                    <SparkleIcon
                      width={16}
                      height={16}
                      className="mr-2.5 inline"
                      stroke="black"
                    />
                    2x Live Course Passes
                  </span>
                  <span className="ml-6 block">Renewal discount: $995</span>
                  <span className="ml-6 mb-4 block">Original price: $2,790</span>
                  {getCohortPassCta()}
                </div>
              ) : (
                // If not individual or not on the individual plan card, show info box without a CTA
                <div className="px-5 py-9">
                  <span className="block font-semibold">Live Course Passes</span>
                  <span className="block">
                    Live Course Passes can be added after you select a plan.
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}
