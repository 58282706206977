/* eslint-disable camelcase */
import { Link } from 'react-router-dom'

import EmbeddedHubspotForm from 'domains/Patronage/EmbeddedHubspotForm'

import { trackCtaClicked, trackMarketingFormSubmitted } from 'utils/tracking/analytics'

import { ReactComponent as ReforgeLogo } from 'images/reforge-logo.svg'

const FormHeader = () => {
  return (
    <div className="flex h-20 w-full items-center bg-black pl-7 font-sans text-xl uppercase tracking-vast text-white">
      <Link to="/">
        <ReforgeLogo className="h-5 w-full text-rb-white" />
      </Link>
    </div>
  )
}

const PlanUpdateRequestForm = () => {
  const formId = 'e7fb84d7-11c5-4927-8df3-24c7522d710e'

  const handleFormSubmit = (formData: any) => {
    trackCtaClicked({
      cta_location: 'hubspot_billing_page_contact_form',
      cta_type: 'button',
      text: 'submit to get in touch'
    })

    const el = formData.elements ?? formData?.[0]?.elements

    if (!el) {
      return
    }

    const email = el.email?.value
    const first_name = el.firstname?.value
    const last_name = el.lastname?.value
    const plan_selection = el.plan_selection?.value
    const comments = el.comments?.value

    const form_fields = {
      email,
      first_name,
      last_name,
      plan_selection,
      comments
    }

    trackMarketingFormSubmitted({
      form_fields,
      email,
      form_id: formId,
      form_type: 'hubspot',
      form_name: 'plan update request form',
      location: 'hubspot_billing_page_contact_form',
      url: '/plan-update-request',
      source: 'marketing site',
      type: 'page'
    })
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <FormHeader />
      <EmbeddedHubspotForm
        classes="w-3/5 mt-12"
        region="na1"
        portalId="20491267"
        formId={formId}
        onFormSubmit={handleFormSubmit}
      />
    </div>
  )
}

export default PlanUpdateRequestForm
