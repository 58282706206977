import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

interface ButtonLinkedInProps {
  href: string
}

const ButtonLinkedIn = ({ href }: ButtonLinkedInProps) => (
  <Button
    className="relative py-4 w-full text-sm font-medium normal-case"
    size="medium"
    variant="outline"
    href={href}
    data-method="post"
    title="Continue with Linkedin"
    iconBefore={<SVGIcon name="linkedin-minimal" className="mb-[3px]" />}
  >
    Continue with Linkedin
  </Button>
)

export default ButtonLinkedIn
