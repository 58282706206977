import { Icon } from '@/components/ui/Icon'
import { useEffect, useState } from 'react'
import { isChrome } from 'react-device-detect'
import { Link } from 'react-router-dom'
import TimeShift from 'timeshift-js'

import { ErrorMessage, Loading } from 'components'
import Button from 'components/Button'
import ButtonGoogle from 'components/ButtonGoogle'
import ButtonLinkedIn from 'components/ButtonLinkedIn'
import ButtonMicrosoft from 'components/ButtonMicrosoft'
import DeveloperLogin from 'components/DeveloperLogin'
import { EmbeddedBrowserMessage } from 'components/EmbeddedBrowserMessage'
import { FacePile, FacePileContainer, FacePileUser } from 'components/FacePile'
import SsoLogin from 'components/SsoLogin'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import { useSubscriptionTeamPreviewQuery } from 'gql'

import useInterTabCommunication from 'hooks/useInterTabCommunication'
import useURLParams from 'hooks/useURLParams'

import { LoginParams, getLoginUrl } from 'utils/url/loginUrl'

import { ReactComponent as ReforgeLogoLarge } from 'images/reforge-logo-black.svg'

const LoginContainer = () => {
  const { getParam, deleteParam, getQueryString } = useURLParams()

  const token = getParam('token')
  const mtoken = getParam('mtoken')
  const fwd = getParam('fwd')
  const tos = getParam('tos')
  const reforgeLogin = !!getParam('reforgeLogin')
  const referer = getParam('referer')
  const marketingNav = !!getParam('marketingNav')
  const courseType = getParam('courseType')
  const cmsProgramSlug = getParam('cmsProgramSlug')
  const forEventRSVP = getParam('forEventRSVP') === 'true'
  const eventId = getParam('eventId')
  const userTimezone = getParam('userTimezone')
  const happening = getParam('happening') === 'true'
  const logoutRequest = getParam('logout_request') === 'true'

  const { postMessage } = useInterTabCommunication()

  useEffect(() => {
    if (logoutRequest) {
      // Refresh the page in all tabs when the user logs out to clear the session
      postMessage('reload')
      deleteParam('logout_request')

      const queryString = getQueryString()
      window.history.replaceState(
        null,
        '',
        `/login${queryString ? `?${queryString}` : ''}`
      )
    }
  }, [deleteParam, getQueryString, logoutRequest, postMessage])

  const {
    data: teamPreviewData,
    loading: teamPreviewLoading,
    error: teamPreviewError
  } = useSubscriptionTeamPreviewQuery({
    variables: {
      token: mtoken,
      loginParams: {
        cmsProgramSlug,
        courseType,
        eventId,
        forEventRSVP,
        fwd,
        happening,
        marketingNav,
        mtoken,
        referer,
        reforgeLogin,
        token,
        tos,
        userTimezone
      }
    },
    skip: !mtoken
  })

  if (teamPreviewLoading) return <Loading />
  if (teamPreviewError) {
    return <ErrorMessage error={teamPreviewError} />
  }

  return (
    <Login
      token={token}
      mtoken={mtoken}
      teamName={teamPreviewData?.subscriptionTeamPreview?.name}
      teamMembers={teamPreviewData?.subscriptionTeamPreview?.members}
      teamMemberCount={teamPreviewData?.subscriptionTeamPreview?.totalMembers}
      ssoAuthorizationUrl={
        teamPreviewData?.subscriptionTeamPreview?.ssoAuthorization?.authorizationUrl
      }
      // NOTE: This fallback is treating this issue: https://reforge.atlassian.net/browse/REF-6345
      fwd={fwd || encodeURIComponent(window.location.origin)}
      tos={tos}
      reforgeLogin={reforgeLogin}
      referer={referer}
      marketingNav={marketingNav}
      courseType={courseType}
      cmsProgramSlug={cmsProgramSlug}
      forEventRSVP={forEventRSVP}
      eventId={eventId}
      userTimezone={userTimezone}
      happening={happening}
    />
  )
}

interface TeamMemberInvitedProps {
  teamName: string
  teamMembers: FacePileUser[]
  teamMemberCount: number
  handleClick: () => void
}

const TeamMemberInvited = ({
  teamName,
  teamMembers,
  teamMemberCount,
  handleClick
}: TeamMemberInvitedProps) => {
  return (
    <div
      id="team_welcome"
      className="flex min-h-[100vh] flex-col bg-gradient-135 from-rb-background-paleBlue to-rb-background-paleLavender"
    >
      <a className="mx-16 mt-12 mb-8 self-center xs:self-start" href="/" rel="noreferrer">
        <ReforgeLogoLarge className="w-[200px] min-w-[200px]" />
      </a>
      <div className="flex w-96 flex-col gap-12 self-center rounded-lg bg-rb-white py-20 px-16 shadow-rb-hover lg:w-[575px]">
        <div className="flex flex-col gap-4">
          <h1 className="font-medium leading-8 tracking-wide md:text-[28px]">
            You&#8217;ve been invited to join the{' '}
            <span className="font-semibold">{teamName}</span> membership!
          </h1>
          <FacePileContainer>
            <FacePile users={teamMembers} />
            <div className="text-xs sm:ml-1 sm:text-sm ">{teamMemberCount} members</div>
          </FacePileContainer>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <div className="text-xl font-semibold">
              Unlock step-change growth in your career
            </div>
            <p className="text-sm text-rb-gray-300">
              Make the leap from product manager to product leader, or from beginner to
              growth expert
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-xl font-semibold">Level up on new skills or topics</div>
            <p className="text-sm text-rb-gray-300">
              From learning about experimentation or strategy, to advanced approaches on
              retention & engagement
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-xl font-semibold">
              Solve your toughest work challenges
            </div>
            <p className="text-sm text-rb-gray-300">
              Learn how to analyze your newest feature, improve your activation flow, grow
              your audiece, and more
            </p>
          </div>
        </div>
        <Button fullWidth={true} shape="rounded-full" onClick={handleClick}>
          Join team
        </Button>
      </div>
    </div>
  )
}

const SocialLoginForm = ({
  loginParams,
  handleSSOSignInClick
}: {
  loginParams: LoginParams
  handleSSOSignInClick: () => void
}) => {
  return (
    <>
      <div className="mb-6 flex flex-col gap-6 md:gap-4">
        <ButtonGoogle href={getLoginUrl('google', loginParams)} />
        <ButtonLinkedIn href={getLoginUrl('linkedin', loginParams)} />
        <ButtonMicrosoft href={getLoginUrl('microsoft', loginParams)} />
      </div>
      <div className="flex flex-col mt-8 md:mt-6 text-center">
        <button onClick={handleSSOSignInClick} className="text-rb-teal-200">
          Sign in with SSO
        </button>
      </div>
    </>
  )
}

const SSOLoginForm = ({
  loginParams,
  handleSSOSignInClick
}: {
  loginParams: LoginParams
  handleSSOSignInClick: () => void
}) => {
  return (
    <>
      <SsoLogin loginParams={loginParams} />
      <div className="flex flex-col mt-8 md:mt-6 text-center">
        <button onClick={handleSSOSignInClick} className="text-rb-teal-200">
          Or continue with other options
        </button>
      </div>
    </>
  )
}

// Extends LoginParams so we aren't duplicating ourselves in terms of expected parameter names
export interface LoginProps extends LoginParams {
  teamName?: string | null
  teamMembers?: FacePileUser[]
  teamMemberCount?: number
  ssoAuthorizationUrl?: string | null
}

export const Login = ({
  token = '',
  mtoken = '',
  fwd = '',
  tos = '',
  reforgeLogin = false,
  referer,
  marketingNav = false,
  teamName,
  teamMembers,
  teamMemberCount,
  courseType = '',
  cmsProgramSlug = '',
  forEventRSVP = false,
  eventId,
  userTimezone,
  happening,
  ssoAuthorizationUrl
}: LoginProps) => {
  const loginParams = {
    token,
    fwd,
    tos,
    mtoken,
    reforgeLogin,
    referer,
    marketingNav,
    courseType,
    cmsProgramSlug,
    forEventRSVP,
    eventId,
    userTimezone,
    happening
  }

  const [showTeamMemberInvited, setShowTeamMemberInvited] = useState(!!mtoken)
  const [showSSOForm, setShowSSOForm] = useState(false)

  // ensure we show messaging for unsupported browsers (embedded)
  const isEmbeddedBrowser =
    /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(navigator.userAgent) && !isChrome

  const isTimeTraveled =
    process.env.REACT_APP_I_AM_A_TIME_TRAVELER === 'true' &&
    Math.abs(TimeShift.OriginalDate.now() - Date.now()) > 300000

  if (isTimeTraveled) {
    return (
      <div className="flex min-h-[100vh] flex-col bg-gradient-135 from-rb-background-paleBlue to-rb-background-paleLavender">
        <div className="flex flex-1 flex-col items-center justify-center">
          <div className="text-2xl font-semibold">Time Traveler Detected</div>
          <div className="text-lg font-semibold">
            Please reset the time before attempting to login
          </div>
        </div>
      </div>
    )
  }

  if (isEmbeddedBrowser) {
    return <EmbeddedBrowserMessage />
  }

  if (showTeamMemberInvited) {
    const handleClick = ssoAuthorizationUrl
      ? () => window.location.replace(ssoAuthorizationUrl)
      : () => setShowTeamMemberInvited(false)

    return (
      <TeamMemberInvited
        teamName={teamName || ''}
        teamMembers={teamMembers || []}
        teamMemberCount={teamMemberCount || 0}
        handleClick={handleClick}
      />
    )
  }

  const devMode = process.env.NODE_ENV === 'development'

  return (
    <div
      id="login"
      className="flex justify-center items-center overflow-y-auto p-8 min-h-[100vh] bg-gradient-135 from-rb-background-paleBlue to-rb-background-paleLavender md:p-12"
    >
      <div className="flex justify-center gap-8 w-full max-w-[84rem] h-full md:max-h-[50.25rem]">
        <div className="pt-[9.5rem] px-8 pb-20 font-polysans hidden xl:block">
          <h2 className="pb-16 text-5xl font-normal">
            Unlimited access to leading expertise and the tools you need to ship
            exceptional work.
          </h2>
          <ul className="list-none pl-0">
            {[
              'Level up your career with 40+ expert-led courses',
              'Thousands of real work examples so you never start from scratch',
              'Work smarter with AI-powered productivity tools for product builders'
            ].map((perk) => (
              <li className="pb-5 flex align-top text-xl leading-8" key={perk}>
                <Icon
                  name="Check"
                  className="flex-shrink-0 inline-block h-5 w-5 mr-4 mt-1"
                />
                {perk}
              </li>
            ))}
          </ul>
        </div>
        <div className="min-h-[calc(100vh-4rem)] rounded-2xl bg-rb-white px-8 py-12 shadow-lg w-full flex-shrink-0 md:p-[4.5rem] md:max-w-[35rem] md:min-h-[100%]">
          <a href="/" rel="noreferrer">
            <ReforgeLogoLarge className="h-[1.825rem] mx-auto mb-16" />
          </a>
          <h1 className="mb-8 text-xl font-bold text-center md:mb-16 md:font-medium">
            Welcome to Reforge!
          </h1>
          {!showSSOForm && (
            <div className="mb-8 md:mb-16">
              <SocialLoginForm
                loginParams={loginParams}
                handleSSOSignInClick={() => setShowSSOForm(true)}
              />
            </div>
          )}
          {showSSOForm && (
            <div className="mb-8 md:mb-16">
              <SSOLoginForm
                loginParams={loginParams}
                handleSSOSignInClick={() => setShowSSOForm(false)}
              />
            </div>
          )}
          {devMode && <DeveloperLogin loginParams={loginParams} />}

          {!devMode && (
            <div className="text-center">
              <RfParagraphMini className="m-auto mb-0 max-w-[18.75rem] text-rb-gray-300">
                By creating an account, you agree to{' '}
                <Link
                  to="/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-rb-gray-300"
                >
                  Reforge&apos;s Terms & Conditions
                </Link>{' '}
                and{' '}
                <Link
                  to="/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-rb-gray-300"
                >
                  Privacy Policy
                </Link>
                <br />
                <br />
                Questions? Contact{' '}
                <a href="mailto:Hello@reforge.com" className="underline text-rb-gray-300">
                  Hello@reforge.com
                </a>
              </RfParagraphMini>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LoginContainer
