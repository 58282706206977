import ArtifactCard from 'components/cards/Content/ArtifactCard'

import { ARTIFACTS_INDEX_TRACK_LOCATION } from 'constants/artifacts'

import { Artifact, useFilteredArtifactsQuery } from 'gql'

import LoadingSkeleton from './LoadingSkeleton'
import UpvoteArtifactsButton from './UpvoteArtifactsButton'

export default function NoResults({ data }: any) {
  const { savedData, openAddToBookmarkFolderModal } = data

  const { data: artifacts, loading: artifactsLoading } = useFilteredArtifactsQuery({
    variables: {
      limit: 10,
      filters: {
        sortBy: 'most-popular'
      }
    }
  })

  return (
    <div className="container w-full font-sans ">
      <div className="flex flex-col items-center bg-rb-gray-50 py-[47px]">
        <h2 className="text-xl font-semibold">{"We don't have that... yet."}</h2>
        <h3 className="px-5 text-base font-normal text-rb-gray-300 md:p-0">
          {"Let the community know what you're looking for."}
        </h3>
        <div className="my-7 flex w-max items-center justify-center px-6 py-4">
          <UpvoteArtifactsButton
            linkText="Request Artifact"
            ctaLocation={ARTIFACTS_INDEX_TRACK_LOCATION}
            variant="teal_button"
            shape="rounded"
          />
        </div>
        <div className="flex h-[384px] flex-col items-center rounded-lg bg-white px-6 pt-5">
          <div className="h-[169px] w-[358px] rounded-lg bg-gradient-to-r from-rb-background-paleLavender to-rb-background-paleBlue "></div>

          <h1 className=" m my-9 text-[28px] font-medium  text-rb-gray-300">
            Future Awesome Artifact
          </h1>

          <div className="flex items-center justify-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
            >
              <circle cx="21" cy="21" r="21" fill="#E4E5E3" />
            </svg>

            <div className="ml-[10px] h-6 w-[283px] rounded-lg bg-rb-gray-100"></div>
          </div>
        </div>
      </div>

      <h2 className="my-3 mt-12 text-xl font-semibold">
        Our Top 10 artifacts of all time
      </h2>
      {artifactsLoading && <LoadingSkeleton />}
      {!artifactsLoading && (
        <div className="grid grid-cols-1 gap-[20px] lg:grid-cols-2 xl:grid-cols-[repeat(auto-fill,minmax(320px,auto))] xl:gap-[30px]">
          {!!artifacts?.filteredArtifacts?.totalArtifactsCount &&
            artifacts.filteredArtifacts.artifacts.map((artifact: Artifact) => (
              <ArtifactCard
                key={artifact.id}
                artifact={artifact}
                bookmark={savedData?.savedArtifactsForUser?.find(
                  (bookmark: { sanityId: string }) => bookmark.sanityId === artifact.id
                )}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                additionalRelatedIdentifiers={{
                  is_filtered_reference: false,
                  referrer_name: ARTIFACTS_INDEX_TRACK_LOCATION,
                  is_empty_index_result: true
                }}
              />
            ))}
        </div>
      )}
    </div>
  )
}
