import { useEffect, useState } from 'react'

import { EventRecordingCard } from 'pages/EventRecordings/components/EventRecordingCard'
import { UpcomingEventCard } from 'pages/EventsUpcoming/components/UpcomingEventCard'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import ArtifactCard from 'components/cards/Content/ArtifactCard'
import { CardVariants } from 'components/cards/Content/BaseCard'
import BlogCard from 'components/cards/Content/BlogCard'
import CourseCard from 'components/cards/Content/CourseCard'
import GuideCard from 'components/cards/Content/GuideCard'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import {
  ArtifactAuthor,
  CclGuide,
  ExpertUserProfileFragmentFragment,
  useUserBookmarksQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import TopCreator from '../components/TopCreator'
import UpcomingEventCourses from '../components/UpcomingEventCourse'

export interface LatestOverviewProps {
  authorProfile: ArtifactAuthor
  overviewMode: boolean
  userProfile: ExpertUserProfileFragmentFragment
}

type MixedContent = {
  [key: string]: any
  timestamp?: string
  mixedContentType: 'artifacts' | 'events' | 'guides' | 'courses' | 'blogPosts'
}

const LatestOverviewTab = ({
  authorProfile,
  overviewMode,
  userProfile
}: LatestOverviewProps) => {
  const [mixedContent, setMixedContent] = useState<MixedContent[]>([])
  const { workEntries } = authorProfile
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const { isLoggedIn } = useCurrentUser()
  const { data: allBookmarksData } = useUserBookmarksQuery({
    skip: !isLoggedIn,
    fetchPolicy: 'network-only'
  })
  const artifactBookmarks = allBookmarksData?.currentUser?.artifactBookmarks
  const eventBookmarks = allBookmarksData?.currentUser?.eventBookmarks
  const courseBookmarks = allBookmarksData?.currentUser?.courseBookmarks
  const guideBookmarks = allBookmarksData?.currentUser?.guideBookmarks

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }
  useEffect(() => {
    const artifacts: any = []

    workEntries?.forEach((entry) => {
      const contributions = entry.artifactContributions

      if (!contributions?.length) return

      contributions.forEach((c) => {
        if (!c?.artifact?.publishDate) return
        const date = new Date(c.artifact.publishDate)

        artifacts.push({
          artifact: c.artifact,
          timestamp: date.getTime(),
          mixedContentType: 'artifacts'
        })
      })
    })

    const allBlogPosts =
      userProfile.allBlogPosts?.map((blogPost) => {
        const startTime: any = blogPost.firstPublishedAt
        const date = new Date(startTime)
        return {
          blogPost: {
            ...blogPost,
            id: blogPost.sourceId
          },
          timestamp: date.getTime(),
          mixedContentType: 'blogPosts'
        }
      }) ?? []

    const allCourses =
      userProfile.allCourses?.map((course) => {
        const startTime: any =
          course.upcomingSessions?.[0]?.startsAt || course!.publishedAt
        const date = new Date(startTime)
        return {
          course,
          timestamp: date.getTime(),
          mixedContentType: 'courses'
        }
      }) ?? []

    const allEvents =
      userProfile.allHostedEvents?.map((event) => {
        const date = new Date(event.startsAtUtc)
        return {
          event,
          timestamp: date.getTime(),
          mixedContentType: 'events'
        }
      }) ?? []

    const allGuides =
      userProfile.allGuides?.map((guide) => {
        const date = new Date(guide.publishedAt)
        return {
          guide: {
            ...guide,
            id: guide.sourceId
          },
          // have to keep track of cclEntityId here so we do not lose it for tracking
          // since we transform id (which is the cclEntityId) -> sourceId above
          cclEntityId: guide.id,
          timestamp: date.getTime(),
          mixedContentType: 'guides'
        }
      }) ?? []

    const data = [
      ...artifacts,
      ...allEvents,
      ...allCourses,
      ...allGuides,
      ...allBlogPosts
    ].sort((a, b) => b.timestamp - a.timestamp)

    setMixedContent(data)
  }, [workEntries, userProfile])

  return (
    <div className="flex flex-col gap-4">
      {overviewMode && isMobileView && (
        <>
          <TopCreator
            authorProfile={authorProfile}
            userProfile={userProfile}
            overviewMode={overviewMode}
          />

          <UpcomingEventCourses userProfile={userProfile} overviewMode={overviewMode} />

          <div className="font-sans font-semibold text-base text-rb-gray-400 mt-8">
            Latest contributions
          </div>
        </>
      )}

      {mixedContent?.map((content, index) => {
        const variants = ['teal', 'blue', 'pink', 'green', 'yellow']

        if (content.mixedContentType === 'artifacts') {
          return (
            <ArtifactCard
              key={index}
              pageLocation="expert_profile__latest"
              artifact={content.artifact}
              bookmark={artifactBookmarks?.find(
                (obj) => obj.sanityId === content.artifact.id
              )}
              variant={isMobileView ? CardVariants.Mini : CardVariants.Horizontal}
              hideBookmarkButton={!isLoggedIn}
            />
          )
        } else if (content.mixedContentType === 'events') {
          return content.event.past ? (
            <EventRecordingCard
              key={index}
              event={content.event}
              pageLocation="expert_profile__latest"
              bookmark={eventBookmarks?.find((obj) => obj.eventId === content.event.id)}
              variant={isMobileView ? CardVariants.Mini : CardVariants.Horizontal}
              imgVariants={variants[index % variants.length]}
            />
          ) : (
            <UpcomingEventCard
              key={index}
              pageLocation="expert_profile__latest"
              variant={isMobileView ? CardVariants.Mini : CardVariants.Horizontal}
              event={content.event}
            />
          )
        } else if (content.mixedContentType === 'courses') {
          return (
            <CourseCard
              key={index}
              bookmark={courseBookmarks?.find(
                (obj) => obj?.cclCourse?.id === content.course.id
              )}
              variant={isMobileView ? CardVariants.Mini : CardVariants.Horizontal}
              pageLocation="expert_profile__latest"
              courseSession={content.upcomingSessions?.[0]}
              course={content.course}
              hideBookmarkButton={!isLoggedIn}
            />
          )
        } else if (content.mixedContentType === 'blogPosts') {
          return (
            <BlogCard
              key={index}
              variant={isMobileView ? CardVariants.Mini : CardVariants.Horizontal}
              blogPost={content.blogPost}
              hideBookmarkButton={true}
              pageLocation="expert_profile__latest"
            />
          )
        } else {
          return (
            <GuideCard
              key={index}
              bookmark={guideBookmarks?.find((obj) => obj.sanityId === content.guide.id)}
              pageLocation="expert_profile__latest"
              // add back the cclEntityId as id since that is what GuideCard expects from the CclGuide type)
              guide={{ ...content.guide, id: content.cclEntityId } as CclGuide}
              variant={isMobileView ? CardVariants.Mini : CardVariants.Horizontal}
            />
          )
        }
      })}

      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={allBookmarksData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={
          !allBookmarksData?.currentUser?.hasSeenCollectionsPrompt
        }
      />
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction={'bookmark'}
      />
    </div>
  )
}

export default LatestOverviewTab
