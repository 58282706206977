import { useRef } from 'react'

import { ArtifactSeoTopicTabListSection } from 'domains/ArtifactSeo/ArtifactSeoTopicTabListSection'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { ErrorMessage, Loading } from 'components'

import { Homepage, HomepageContent, useMarketingHomepageQuery } from 'gql'

import AiSection from './AiSection'
import InteractiveContentSection from './InteractiveContentSection/InteractiveContentSection'
import JoinCtaSection from './JoinCtaSection'
import LoggedOutHomepageHero from './LoggedOutHomepageHero'
import LogoSection from './LogoSection'
import TeamSection from './TeamSection'
import TestimonialSection from './TestimonialSection'

const LoggedOutHomepage = () => {
  const { data, loading } = useMarketingHomepageQuery()
  const page = (data?.marketingHomepage || {}) as Homepage
  const { content } = page

  if (!loading && !content) {
    return <ErrorMessage />
  }

  return (
    <MarketingLayout page={page} transparentNav>
      {loading ? (
        <Loading className="mt-64" />
      ) : (
        <LoggedOutHomepageContent content={content} />
      )}
    </MarketingLayout>
  )
}

export const LoggedOutHomepageContent = ({ content }: { content: HomepageContent }) => {
  const interactiveContentSectionRef = useRef<HTMLDivElement>(null)

  const scrollToInteractiveContentSection = () => {
    const section = interactiveContentSectionRef.current
    if (section) {
      setTimeout(() => {
        const rect = section.getBoundingClientRect()
        const top = rect.top + window.pageYOffset
        window.scrollTo({ top, behavior: 'smooth' })
      }, 100) // A small delay to ensure content is fully rendered
    }
  }

  return (
    <>
      <LoggedOutHomepageHero content={content} />
      <LogoSection logos={content?.logos} />
      <div ref={interactiveContentSectionRef}>
        <InteractiveContentSection onTabChange={scrollToInteractiveContentSection} />
      </div>
      <TestimonialSection content={content} />
      <AiSection content={content} />
      <TeamSection content={content} />
      <JoinCtaSection content={content} />
      <div className="px-4 md:px-[4vw] py-[50px] lg:py-[100px]">
        <div className="mx-auto max-w-[1280px]">
          <ArtifactSeoTopicTabListSection />
        </div>
      </div>
    </>
  )
}

export default LoggedOutHomepage
