import { useRef, useState } from 'react'
import Rater from 'react-rater'

import RaterIcon from 'components/RaterIcon'

import { useContentFeedbackMutation } from 'gql'

import { track } from 'utils/tracking/segment'

const helpers = [
  'Not useful at all',
  'Not very useful',
  'Somewhat useful',
  'Useful',
  'Very useful'
]

interface IFeedbacksCmsContent {
  cmsContentId: string
  cmsProgramId: string
  userId: string
  contentType: string | undefined
}

const FeedbacksCmsContent = ({
  cmsContentId,
  cmsProgramId,
  userId,
  contentType = 'material'
}: IFeedbacksCmsContent) => {
  const [giveContentFeedback] = useContentFeedbackMutation()

  const [rating, setRating] = useState(0)
  const helperTextRef = useRef<HTMLDivElement>(null)

  const save = (rating: number) => {
    // @ts-ignore - 'Feedback-qualitative-action' event is not defined in Segment JIRA#REF-5159
    track('Feedback-qualitative-action', {
      user_id: userId,
      qual_rating: false
    })

    giveContentFeedback({
      variables: {
        input: {
          cmsSectionId: `${cmsContentId}`,
          cmsProgramId: `${cmsProgramId}`,
          quant: rating
        }
      }
    })
  }

  const onRatingHover = ({ rating }: { rating: number }) => {
    // We are manipulating the dom because there is a bug in Rater component's onmouseover
    if (helperTextRef.current) {
      helperTextRef.current.innerHTML = helpers[rating - 1]
    }
  }

  const changeRating = ({ rating }: { rating: number }) => {
    setRating(rating)
    save(rating)
    // @ts-ignore - 'Feedback-quant-action' event is not defined in Segment JIRA#REF-5159
    track('Feedback-quant-action', {
      user_id: userId,
      quant_rating: rating
    })
  }

  return (
    <div className="inline-block text-center">
      <>
        <p className="!mt-0 !mb-4 text-left font-sans text-2xl leading-7 text-rb-gray-500 sm:hidden sm:font-bold">
          Great work! How useful was this for you?
        </p>
        <p className="mt-0 !mb-4 hidden text-left font-sans text-2xl leading-7 text-rb-gray-500 sm:block sm:!font-bold">
          Great work! You&apos;ve completed this {contentType.toLowerCase()}.
          <br />
          How useful was it for you?
        </p>
      </>
      <div className="sm:float-left sm:-ml-2">
        <div className="relative mb-2.5 inline-block">
          <Rater
            total={5}
            rating={rating}
            onRating={onRatingHover}
            onRate={changeRating}
            uk-toggle="target: #feedback-helper-text-inline; cls: content-feedback__helper-text--hidden; mode: hover;"
          >
            <RaterIcon
              name="rounded-star"
              className="mb-2 px-1 sm:px-2"
              svgClassName="w-10 sm:w-8"
            />
          </Rater>
        </div>
        <div
          id="feedback-helper-text-inline"
          ref={helperTextRef}
          className="content-feedback__helper-text content-feedback__helper-text--hidden font-medium"
        >
          &nbsp;
        </div>
      </div>
    </div>
  )
}

export default FeedbacksCmsContent
